<script setup lang="ts">
import type { SliceInterface, VoixCheckboxFieldInterface, VoixColorFieldInterface, VoixSelectFieldInterface, VoixTextFieldInterface } from '@voix/types'

import { usePageStore } from '@voix/store/pageStore'
import { SwiperSlide } from 'swiper/vue'
import { Pagination } from 'swiper/modules'
import SlicesHyattSpecialOffer from '@/components/slices/hyatt/SlicesHyattSpecialOffer.vue'
import SlicesHyattTwentyTwentyTwoSpecialOffer from '@/components/slices/hyatt/TwentyTwentyTwo/SlicesHyattTwentyTwentyTwoSpecialOffer.vue'
import SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal from '@/components/slices/playaresorts/special-offers/SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal.vue'
import SlicesPlayaresortsSpecialOffersSpecialOfferCard from '@/components/slices/playaresorts/special-offers/SlicesPlayaresortsSpecialOffersSpecialOfferCard.vue'

const props = defineProps({
  slice: {
    type: Object as PropType<SliceInterface>,
    required: true,
  },
  fields: {
    type: Object as PropType<{
      title: VoixTextFieldInterface
      titleAbove: VoixCheckboxFieldInterface
      pageSpecialSlice: VoixSelectFieldInterface
      paginationColor: VoixColorFieldInterface
    }>,
    required: true,
  },
})

defineSlice({
  name: { label: 'Special Offer Slider', group: 'playaresorts' },
  fields: {
    title: { type: 'text', label: 'Title', editorLabel: true, default: 'Special Offers' },
    numberOfSlides: { type: 'number', label: 'Number of Slides', default: 2 },
    horizontal: { type: 'checkbox', label: 'Horizontal?' },
    pageSpecialSlice: {
      label: 'Page Special Slice',
      type: 'select',
      options: {
        SliceData: 'Use Slice Data',
        SlicesHyattSpecialOffer: 'Hyatt Special Offer',
        SlicesHyattTwentyTwentyTwoSpecialOffer: 'Hyatt 2022 Special Offer',
        SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal: 'Special Offer Horizontal',
        SlicesPlayaresortsSpecialOffersSpecialOfferCard: 'Special Offer Card',
      },
      default: 'SliceData',
    },
    paginationColor: { type: 'color', label: 'Pagination Color' },
  },
  slots: [{ name: 'default', allowedElements: [] }],
})

const pageStore = usePageStore()
const currentPage = pageStore.currentPage
const { data: pageSpecials } = await useVoixAsyncData(
  `/api/playa/page-specials/${currentPage.id}/${props.fields.pageSpecialSlice.value}`,
)
console.log(`/api/playa/page-specials/${currentPage.id}/${props.fields.pageSpecialSlice.value}`)
const specialSlice = computed(() => {
  switch (props.fields.pageSpecialSlice.value) {
    case 'SlicesHyattSpecialOffer':
      return SlicesHyattSpecialOffer
    case 'SlicesHyattTwentyTwentyTwoSpecialOffer':
      return SlicesHyattTwentyTwentyTwoSpecialOffer
    case 'SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal':
      return SlicesPlayaresortsSpecialOffersSpecialOfferHorizontal
    case 'SlicesPlayaresortsSpecialOffersSpecialOfferCard':
      return SlicesPlayaresortsSpecialOffersSpecialOfferCard
  }
  return null
})
</script>

<template>
  <div>
    <div v-if="slice.elements && slice.elements.length > 0" class="flex justify-center overflow-hidden">
      <div class="p-8 container text-center flex flex-col items-center">
        <div class="text-3xl uppercase title mb-8 font-light" v-html="fields.title.value" />
        <ClientOnly>
          <general-swiper :slides-per-view="fields.numberOfSlides.value" :arrow-color="fields.paginationColor.value" class="hidden lg:block w-full h-full flex">
            <VoixSlice
              v-for="e in slice.elements[0].elements"
              :key="e.id" :slice="e"
              :class="{ 'w-full': !fields.horizontal.value }" class="w-full h-full object-contain"
            />
          </general-swiper>
          <div class="block lg:hidden">
            <VoixSlice
              v-for="e in slice.elements[0].elements"
              :key="e.id" :slice="e"
              class="block w-full"
            />
          </div>
        </ClientOnly>
      </div>
    </div>
    <div v-if="fields.pageSpecialSlice.value && fields.pageSpecialSlice.value !== 'SliceData'" class="flex justify-center overflow-hidden">
      <div class="p-8 container text-center flex flex-col items-center">
        <div class="text-3xl uppercase title mb-8 font-light" v-html="fields.title.value" />
        <ClientOnly>
          <general-swiper :slides-per-view="fields.numberOfSlides.value" :arrow-color="fields.paginationColor.value" class="hidden lg:block w-full h-full flex">
            <component
              :is="specialSlice" v-for="(special, index) in pageSpecials" :key="index" :fields="special"
              :class="{ 'w-full': !fields.horizontal.value }" class="w-full h-full object-contain"
            />
          </general-swiper>
          <div class="block lg:hidden">
            <component
              :is="specialSlice" v-for="(special, index) in pageSpecials" :key="index" :fields="special"
              :class="{ 'w-full': !fields.horizontal.value }" class="w-full object-contain"
            />
          </div>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>
